@tailwind base;
@tailwind components;
@tailwind utilities;
html,
body,
#root {
  height: 100%;
}
.bg-login{
background: url('../login-bg.jpg');
background-repeat: no-repeat;
}
.bg-lm{
  background-color: #17A9E2!important;
  color: #eee;
}
.line-top{
  border-top: 4px solid #17A9E2;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.line-bottom-gray {
  border-bottom: 1px solid #b3b3b3;
}

.logo-accelerate{
  width: 10rem;
}

.card-menu {
  cursor: pointer;
  max-height: fit-content;
  max-width: 100%;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  transform: scale(1);
  transition: box-shadow 100ms ease-in-out,
  transform 100ms ease-in-out;
}

.card-menu:hover {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  transform: scale(1.05);
  transition: box-shadow 200ms ease-in-out, transform 200ms ease-in-out;
}
/* .header-presentation .icon{
  display: inline;
}

.header-presentation {
  height: 40vh;
} */

.jumborton-modul-int {
  height: 40vh;
  background-color: #CCEDF5;
}

.jumborton-modul-cover {
  height: 40vh;
}

/* @media only screen and (min-width: 1440px) {

  .jumborton-modul-int {
    height: 0vh;
  }

  .jumborton-modul-cover {
    height: 60vh;
  }
} */

.min-h-half{
  min-height: 80%;
}
.text-white a{
  color:#fff!important
}
/* Quiz */
.quiz {
  text-align: left;
}

.quiz .form-check {
  margin: 0.3rem 0;
  background: #f7f7f7;
  list-style: none;
  padding: 0;
}
.quiz input{
  margin: 1rem;
}
.quiz-question {
  font-weight: bold;
  display: block;
  padding: 30px 0 10px 0;
  margin: 0;
}

.quiz-answer {
  width: 100%;
  margin: 0;
  padding: 10px;
  margin-bottom: 5px;
  cursor: pointer;
}

.quiz-answer:hover {
  background: #eee;
}
.ant-btn-primary {
  border-color: #17A9E2!important;
  background: #17A9E2 !important;
}
/* Presentation Caurosel */
.carousel.carousel-slider .control-arrow{
  height: 50px;
  margin: 100px 0 0 0;
  color: #17A9E2!important;
  padding: 0px;
  background: #17A9E2 !important;
}
.ant-result-title{
  background-color: #17A9E2;
  color: #eee!important;
}
/* Ck Editor */

.title-menu{
  margin-top: 50px;
  color: #fff;
  background-color: #17A9E2;
  font-family: 'Roboto',
  Sans-serif;
  font-weight: 500;
  font-size: 24px;
  padding: 20px 40px;
  border-radius: 5px;
}

/* .container-menu {
  max-width: 960px;
  margin: 0 auto 30px auto;
}
@media (max-width: 575.98px) {
  .container-menu {
    margin: 0 15px 30px 15px;
  }
} */

.testimoni__detail {
  width: 90%;
  gap: clamp(8px,2vw,46px);
  height: fit-content;
  margin: 0 auto;
  padding: 69px 46px;
}

.testimoni__detail-right {
  gap: 8px;
}

@media only screen and (max-width: 767px) {
  .testimoni__detail {
      gap: 24px;
      padding: 16px;
  }

  .testimoni__detail-right {
      gap: 24px;
  }
}

.custom-slick-review .slick-prev:before, .custom-slick-review .slick-next:before {
    color: #141414;
}